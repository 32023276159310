import {LIMIT_RECORDS_API, TIMEZONE_DEFAULT,} from "./constants";

import moment from 'moment'

export class Utils {
    static composeUrlWithLimitAndOffset(url, currentPage=1, limit=LIMIT_RECORDS_API) {
        return url + "?limit=" + limit + "&offset=" + (currentPage - 1) * limit;
    }

    static composeUrlWithInfiniteLimit(url) {
        return url + "?limit=99999999";
    }

    static b64EncodeUnicode (str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(
            encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
                match,
                p1
            ) {
                return String.fromCharCode("0x" + p1);
            })
        );
    }

    static getNumberPageByCountLimit(count, limit) {
        let pagesNumber = Math.floor(count / limit);
        let pagesNumberRest = count %  limit;

        if (pagesNumberRest !== 0) {
            pagesNumber++
        }

        return pagesNumber;
    }

    static getDayOfWeekISO(isoDate) {
        let dayOfWeek = new Date(isoDate).getDay();
        if (dayOfWeek === 0) {
            return 6
        } else {
            return dayOfWeek - 1
        }
    }

    static getLongDateFromISO(isoDate) {
        let timezone = Utils.getDefaultTimezone();

        if (!isoDate) {
            return Utils.getStringOrNA(isoDate)
        }

        return new Date(isoDate).toLocaleDateString('it', {
            hour: '2-digit',
            minute: '2-digit',
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            timeZone: timezone})
    }


    static getAppointmentDateStringFromIso(isoDate) {
        let timezone = Utils.getDefaultTimezone();
        return new Date(isoDate).toLocaleDateString('it', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            timeZone: timezone})
    }

    /**
     * Conversion short from iso ISO8601 into readable string
     */
    static getShortDateStringFromISO(dateISO) {
        let date = new Date(dateISO);
        return Utils.getShortDateStringFromObj(date);
    }

    static getShortDateStringUTCFromISO(dateISO) {
        let date = new Date(dateISO);
        return Utils.getShortDateStringUTCFromObj(date);
    }

    static getBeatifyShortDateStringFromISO(dateISO) {
        let date = new Date(dateISO);

        let dateString = Utils.getAppointmentDateStringFromIso(date);

        let today = moment().startOf('day');
        let yesterday = moment().subtract(1, 'days').startOf('day');
        let tomorrow = moment().add(1, 'days').startOf('day');


        if (moment(date).isSame(today, 'd')) {
            dateString = 'Oggi';
        } else if (moment(date).isSame(tomorrow, 'd')) {
            dateString = 'Domani';
        } else if (moment(date).isSame(yesterday, 'd')) {
            dateString = 'Ieri';
        }

        return dateString;
    }

    static getShortDateStringFromObj(obj) {
        let timezone = Utils.getDefaultTimezone();
        return new Date(obj).toLocaleDateString('it', {
            day:'2-digit',
            month: '2-digit',
            year:'numeric',
            timeZone: timezone});
    }

    static getShortDateStringUTCFromObj(obj) {
        return new Date(obj).toLocaleDateString('it', {
            day:'2-digit',
            month: '2-digit',
            year:'numeric',
            timeZone: 'UTC'});
    }


    /**
     * Conversion from iso ISO8601 into readable string
     */
    static getDateStringFromISO(dateISO) {
        return Utils.getShortDateStringFromISO(dateISO) + " " + Utils.getHourStringFromISO(dateISO);

    }

    static getDateArrayStringFromISO(arrayDate) {
        let newDateArray = [];
        for (let i = 0; i < arrayDate.length; i++) {
            newDateArray.push(Utils.getDateStringFromISO(arrayDate[i]));
        }
        return newDateArray;
    }

    static getHourStringFromISO(dateISO) {
        let format = 'HH:mm';
        let timezone = Utils.getDefaultTimezone();
        return moment(dateISO).tz(timezone).format(format)

    }

    static getHourStringFromISOLocal(dateISO) {
        let format = 'HH:mm';
        return moment(dateISO).local().format(format)

    }

    static getStringOrNA(string) {
        if (string) {
            return string;
        }
        return 'NA'
    }

    static getDefaultTimezone() {
        return TIMEZONE_DEFAULT;
    }

    static validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static validatePhone(phone) {
        const re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
        return re.test(String(phone).toLowerCase());
    }

    static validateHeightPatient(height) {
        if (height > 0 && height < 250) {
            return true
        }

        return false
    }

    static validateWeightPatient(weight) {
        if (weight > 0 && weight < 350) {
            return true
        }

        return false
    }


}


export const moveUpDatetimePopup = () => {
    let elWithClass = document.getElementsByClassName("vdatetime");
    if (elWithClass.length > 0) {
        for (let i = 0; i < elWithClass.length; i++) {
            if (elWithClass[i].childElementCount > 0) {
                let element = elWithClass[i].lastChild;
                if (element && element.style) {
                    element.style.zIndex = "9999";
                    element.style.position = "fixed";
                    document.body.appendChild(element)
                }
            }
        }
    }
}
