import axios from "axios";
import {
    BASE_URL_API,
    LS_TOKEN_KEY,
    URL_API_USER_ME,
    URL_API_USER_LOGIN,
    URL_API_RESEARCH_PROJECT,
    URL_API_RELATIVE_PATIENTS,
    URL_API_RELATIVE_ENTRIES,
    URL_API_RELATIVE_FORM_FIELDS,
    URL_API_RELATIVE_DASHBOARD,
    URL_API_RELATIVE_CATEGORY,
    URL_API_RELATIVE_EXPORT,
    URL_API_HOSPITALS
} from "../common/constants";
import {Utils} from "../common/utils";

const client = axios.create({
  baseURL: BASE_URL_API,
  responseType: "json"
});

function generateAuthorizationHeader() {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(LS_TOKEN_KEY)
    }
  };
}

// USERS

export const doLoginUser = (username, password) => {
  const params = {
    username,
    password
  };
  return client
    .post(URL_API_USER_LOGIN, params)
    .then(res => {
      console.log("Login success", res.data);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getUserInfo = () => {
  return client
    .get(URL_API_USER_ME, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

// PROJECT RESEARCH

export const getResearchProjects = (page) => {
    return client
        .get(Utils.composeUrlWithLimitAndOffset(URL_API_RESEARCH_PROJECT, page), generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getResearchProjectDetail = (id) => {
    return client
        .get(URL_API_RESEARCH_PROJECT + id, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

// PATIENTS

export const getPatients = (idResearchProject, page, selectedHospital) => {
    var hospitalParamString = ""

    if(selectedHospital !== 0){
        hospitalParamString = `&hospital=${selectedHospital}`
    }

    return client
        .get(Utils.composeUrlWithLimitAndOffset(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_PATIENTS,
            page) + hospitalParamString, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getAllPatients = (idResearchProject) => {
    return client
        .get(Utils.composeUrlWithInfiniteLimit(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_PATIENTS), generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data.results;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


export const createPatient = (idResearchProject, external_code) => {
    const data = {
        external_code
    };
    return client
        .post(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_PATIENTS, data, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

// ENTRIES

export const getEntries = (idResearchProject, categoryEntry, page, selectedHospital) => {
    var hospitalParamString = ""

    if(selectedHospital !== 0){
        hospitalParamString = `&hospital=${selectedHospital}`
    }

    return client
        .get(Utils.composeUrlWithLimitAndOffset(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_ENTRIES,
            page) + "&category=" + categoryEntry + hospitalParamString, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};



export const getEntry = (idResearchProject, idEntry) => {
    return client
        .get(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_ENTRIES + "/" + idEntry, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const deleteEntry = (idResearchProject, idEntry) => {
  return client.delete(URL_API_RESEARCH_PROJECT
    + idResearchProject
    + URL_API_RELATIVE_ENTRIES + "/" + idEntry, generateAuthorizationHeader())
    .then(res => {
      console.log(res.data);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
}


export const createEntry = (idResearchProject, category, patient, note, data, compilingDoctor, compilationDate) => {
    let dataArrayCleaned = [];

    for (const [, value] of Object.entries(data)) {
        dataArrayCleaned.push(value)
    }

    const dataToPost = {
        category,
        patient,
        note,
        data: dataArrayCleaned,
      compiling_doctor: compilingDoctor,
      compilation_date: compilationDate
    };

    return client
        .post(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_ENTRIES, dataToPost, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


export const updateEntry = (idResearchProject, idEntry, patient, note, data, compilingDoctor, compilationDate) => {

    let dataArrayCleaned = [];

    for (const [, value] of Object.entries(data)) {
        dataArrayCleaned.push(value)
    }

    const dataToPost = {
        data: dataArrayCleaned,
        patient,
        note,
        compiling_doctor: compilingDoctor,
        compilation_date: compilationDate
    };

    console.log(patient)

    return client
        .put(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_ENTRIES + '/' + idEntry, dataToPost, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


// FORM FIELDS

export const getFormFields = (idResearchProject, categoryEntry) => {
    return client
        .get(Utils.composeUrlWithInfiniteLimit(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_FORM_FIELDS) + "&category=" + categoryEntry, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data.results;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


// DASHBOARD
export const getDashboardData = (idResearchProject, selectedHospital) => {
    var hospitalParamString = ""

    if(selectedHospital !== 0){
        hospitalParamString = `?hospital=${selectedHospital}`
    }

    return client
        .get(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_DASHBOARD + hospitalParamString, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


export const downloadExcelDocument = (idResearchProject, categoryEntry) => {

    let option = {
        responseType: 'arraybuffer',
        headers: {
            Authorization: "Bearer " + localStorage.getItem(LS_TOKEN_KEY)
        }
    };



    return client
        .get(URL_API_RESEARCH_PROJECT
            + idResearchProject
            + URL_API_RELATIVE_CATEGORY + "/" + categoryEntry + URL_API_RELATIVE_EXPORT , option)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


export const getAllHospital = () => {
    return client
        .get(Utils.composeUrlWithInfiniteLimit(URL_API_HOSPITALS), generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data.results;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


