<template>
    <div class="loader">
        <div id="loader-2">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SpinnerLoader"
    };
</script>
